var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-verify-order",
      attrs: {
        width: "98%",
        title: "Order Verification",
        visible: _vm.dialogVerifyOrder,
      },
      on: {
        close: _vm.handleVerifyOrderDialogClose,
        open: _vm.handleVerifyOrderDialogOpen,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "orderVerificationForm",
          staticClass: "el-form-verify-order",
          attrs: {
            model: _vm.orderVerificationForm,
            "validate-on-rule-change": false,
          },
        },
        [
          _c("h1", { staticClass: "margin-0" }, [_vm._v("Product")]),
          _c(
            "div",
            { staticClass: "div-product-body" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "verifyProductTable",
                  attrs: {
                    "row-key": "id",
                    data: _vm.orderVerificationForm
                      .orderVerificationProductTableData,
                    height: "100%",
                  },
                  on: { "selection-change": _vm.handleProductSelectionChange },
                },
                [
                  _c("el-table-column", {
                    key: "checkbox",
                    attrs: { type: "selection", "min-width": "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Product Name",
                      prop: "sysProductName",
                      "show-overflow-tooltip": "",
                      "min-width": "200px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Barge Name", "min-width": "200px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.jobInfo.selectedJobs.length > 0
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.jobInfo.selectedJobs,
                                    function (item) {
                                      return _c(
                                        "p",
                                        {
                                          key: item.jobId,
                                          staticClass: "margin-0",
                                        },
                                        [_vm._v(_vm._s(item.bargeName))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.orderType !== _vm.ORDER_TYPE.EX_WHARF
                    ? _c("el-table-column", {
                        attrs: { "min-width": "200px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", [_vm._v("* ")]),
                                  _vm._v("eBDN No"),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: `orderVerificationProductTableData.${scope.$index}.jobInfo.selectedJobs`,
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            multiple: "",
                                            placeholder: "select",
                                            "value-key": "jobId",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.handleSelectedJobsChange(
                                                scope.row.jobInfo.selectedJobs,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              scope.row.jobInfo.selectedJobs,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row.jobInfo,
                                                "selectedJobs",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.jobInfo.selectedJobs",
                                          },
                                        },
                                        _vm._l(
                                          scope.row.jobInfo.jobOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.jobId,
                                              attrs: {
                                                label: item.docNumber,
                                                value: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4259504238
                        ),
                      })
                    : _c("el-table-column", {
                        attrs: { label: "CQ Number", width: "230" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.jobInfo.selectedJobs.length > 0
                                  ? _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "select",
                                          "value-key": "jobId",
                                          disabled: "",
                                        },
                                        model: {
                                          value: scope.row.jobInfo.selectedJobs,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row.jobInfo,
                                              "selectedJobs",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.jobInfo.selectedJobs",
                                        },
                                      },
                                      _vm._l(
                                        scope.row.jobInfo.jobOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.jobId,
                                            attrs: {
                                              label: item.docNumber,
                                              value: item,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", [_vm._v("-")]),
                              ]
                            },
                          },
                        ]),
                      }),
                  _vm.orderType !== _vm.ORDER_TYPE.EX_WHARF
                    ? _c("el-table-column", {
                        attrs: { "min-width": "200px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", [_vm._v("* ")]),
                                  _vm._v("eBDN Date"),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: `orderVerificationProductTableData.${scope.$index}.supplyDate`,
                                            rules: _vm.rules.reqRule,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              model: {
                                                value: scope.row.supplyDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "supplyDate",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.supplyDate",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.jobInfo.ebdnDates,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    label: item,
                                                    value: item.replace(
                                                      /\s*\(.*?\)\s*/g,
                                                      ""
                                                    ),
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          602633948
                        ),
                      })
                    : _c("el-table-column", {
                        attrs: { label: "CQ Date", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.jobInfo.selectedJobs.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        scope.row.jobInfo.selectedJobs,
                                        function (item) {
                                          return _c("p", { key: item.jobId }, [
                                            _c("i", {
                                              staticClass: "el-icon-time",
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(item.docDate)),
                                            ]),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c("div", [_vm._v("-")]),
                              ]
                            },
                          },
                        ]),
                      }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "Min Qty", "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", { staticClass: "margin-0 margin-btm-22" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.numberWithCommas(
                                    scope.row.minimumQuantity,
                                    3
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "Max Qty", "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", { staticClass: "margin-0 margin-btm-22" }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.maximumQuantity
                                    ? _vm.numberWithCommas(
                                        scope.row.maximumQuantity,
                                        3
                                      )
                                    : "-"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "Final Qty",
                      "min-width": "150px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", { staticClass: "margin-0 margin-btm-22" }, [
                              _vm._v(
                                _vm._s(
                                  scope.row.finalQuantity
                                    ? _vm.numberWithCommas(
                                        scope.row.finalQuantity,
                                        3
                                      )
                                    : "-"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "verifiedQuantity", "min-width": "200px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("* ")]),
                            _vm._v("Delivered Quantity"),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `orderVerificationProductTableData.${scope.$index}.verifiedQuantity`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    controls: false,
                                    precision: 3,
                                    min: null,
                                  },
                                  model: {
                                    value: scope.row.verifiedQuantity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row,
                                        "verifiedQuantity",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.verifiedQuantity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.orderType !== _vm.ORDER_TYPE.EX_WHARF
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "bdnPdfId",
                          label: "BDN Document",
                          "min-width": "150px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.jobInfo.selectedJobs.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "margin-btm-22" },
                                        _vm._l(
                                          scope.row.jobInfo.selectedJobs,
                                          function (item) {
                                            return _c(
                                              "div",
                                              { key: item.jobId },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.previewFile(
                                                          item.bdnPdfId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.docNumber)
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    : _c("div", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          687947936
                        ),
                      })
                    : _c("el-table-column", {
                        attrs: { label: "Loading Documents", width: "200" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.jobInfo.selectedJobs.length > 0
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        scope.row.jobInfo.selectedJobs,
                                        function (item) {
                                          return _c(
                                            "div",
                                            { key: item.jobId },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewFile(
                                                        item.cqPdfId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `[${item.docNumber}] CQ File`
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: { margin: "0" },
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.previewFile(
                                                        item.coqPdfId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `[${item.docNumber}] COQ File`
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _c("div", [_vm._v("-")]),
                              ]
                            },
                          },
                        ]),
                      }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "Price", "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("p", { staticClass: "margin-0 margin-btm-22" }, [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.numberWithCommas(scope.row.price, 3)
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  !(
                    _vm.order &&
                    _vm.order.termContract &&
                    _vm.order.termContract.termContractType === "TIME_CHARTER"
                  )
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "finalBillingPrice",
                          "min-width": "200px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", [_vm._v("* ")]),
                                  _vm._v("Final Billing Price"),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: `orderVerificationProductTableData.${scope.$index}.finalBillingPrice`,
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: {
                                          controls: false,
                                          precision: 3,
                                          min: null,
                                        },
                                        model: {
                                          value: scope.row.finalBillingPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "finalBillingPrice",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "scope.row.finalBillingPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          46290827
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { prop: "currency", "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("* ")]),
                            _vm._v("Currency"),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `orderVerificationProductTableData.${scope.$index}.currency`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: scope.row.currency,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "currency", $$v)
                                      },
                                      expression: "scope.row.currency",
                                    },
                                  },
                                  _vm._l(_vm.currencyList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.code,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  !(
                    _vm.order &&
                    _vm.order.termContract &&
                    _vm.order.termContract.termContractType === "TIME_CHARTER"
                  )
                    ? _c("el-table-column", {
                        attrs: { "min-width": "200px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", [_vm._v("* ")]),
                                  _vm._v("Price Type"),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          on: {
                                            change: function ($event) {
                                              return _vm.handlePriceTypeSelect(
                                                $event,
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                          model: {
                                            value: scope.row.priceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "priceType",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.priceType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.ORDER_PRICE_TYPE_OPTIONS,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3871051900
                        ),
                      })
                    : _vm._e(),
                  !(
                    _vm.order &&
                    _vm.order.termContract &&
                    _vm.order.termContract.termContractType === "TIME_CHARTER"
                  )
                    ? _c("el-table-column")
                    : _vm._e(),
                  !(
                    _vm.order &&
                    _vm.order.termContract &&
                    _vm.order.termContract.termContractType === "TIME_CHARTER"
                  )
                    ? _c("el-table-column", {
                        attrs: { type: "expand", "min-width": "50px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("table-col-product-pricing", {
                                    attrs: {
                                      parent: "DialogVerifyOrder",
                                      nominationDate: new Date(
                                        _vm.nominationDate
                                      ),
                                      scopeRow: scope.row,
                                      scopeIndex: scope.$index,
                                      priceProp:
                                        "orderVerificationProductTableData",
                                      priceType: scope.row.priceType,
                                      priceMethodologyList:
                                        _vm.priceMethodologyList,
                                      rulesReq: _vm.rules.reqRule,
                                      notReqRule: _vm.rules.notReqRule,
                                      holidayMap: _vm.holidayMap,
                                      resetProductPriceWatcher:
                                        _vm.resetProductPriceWatcher,
                                    },
                                    on: {
                                      handlePriceInfoChange:
                                        _vm.handlePriceInfoChange,
                                      handleReferencePriceChange:
                                        _vm.handleReferencePriceChange,
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3861174837
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("h1", { staticClass: "margin-btm-0" }, [_vm._v("Other Cost")]),
          _c(
            "div",
            { staticClass: "div-other-costs-body" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "50%" },
                  attrs: {
                    data: _vm.orderVerificationForm
                      .orderVerificationOtherCostTableData,
                    height: "100%",
                  },
                  on: {
                    "selection-change": _vm.handleOtherCostSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    key: "checkbox",
                    attrs: { type: "selection", "min-width": "55" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "description", label: "Description" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Total Cost" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.numberWithCommas(scope.row.cost, 3)
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "supplyDate", label: "Supply Date" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("* ")]),
                            _vm._v("Supply Date"),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `orderVerificationOtherCostTableData.${scope.$index}.supplyDate`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  model: {
                                    value: scope.row.supplyDate,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "supplyDate", $$v)
                                    },
                                    expression: "scope.row.supplyDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "currency", label: "Currency" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: scope.row.currency,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "currency", $$v)
                                  },
                                  expression: "scope.row.currency",
                                },
                              },
                              _vm._l(_vm.currencyList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.code, value: item.code },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleVerifyOrderDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !(
                  _vm.orderVerificationProductsSelected.length > 0 ||
                  _vm.orderVerificationOtherCostSelected.length > 0
                ),
              },
              on: { click: _vm.handleVerifyOrderDialogConfirm },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }